
export default {
  name: "SwiperOpinions",

  data() {
    return {
      swiperOption: {
        loop: true,
        freeMode: true,
        spaceBetween: 20,
        grabCursor: true,
        slidesPerView: 1,
        autoplay: false,
        speed: 1000,
        freeModeMomentum: false,
        coverflowEffect: {
          depth: 100,
          modifier: 1,
          rotate: 5,
          stretch: 0,
        },
        freeModeMomentumBounce: false,
        freeModeMomentumRatio: 0.1,
        freeModeMomentumVelocityRatio: 0.8,
        freeModeSticky: true,
        navigation: {
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        },
      },

      slides: [
        {
          masses: this.$t('slide1Masses'),
          fullName: this.$t('slide1FullName'),
          info: this.$t('slide1Info'),
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses: this.$t('slide2Masses'),
          fullName: this.$t('slide2FullName'),
          info: this.$t('slide2Info'),
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses: this.$t('slide3Masses'),
          fullName: this.$t('slide3FullName'),
          info: this.$t('slide3Info'),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t('slide4Masses'),
          fullName: this.$t('slide4FullName'),
          info: this.$t('slide4Info'),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t('slide5Masses'),
          fullName: this.$t('slide5FullName'),
          info: this.$t('slide5Info'),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t('slide6Masses'),
          fullName: this.$t('slide6FullName'),
          info: this.$t('slide6Info'),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t('slide7Masses'),
          fullName: this.$t('slide7FullName'),
          info: this.$t('slide7Info'),
          pic: "/assets/images/flags/Flags-1.png",
        },
      ],
    };
  },
  mounted() {
    if (window.innerWidth > 960) {
      this.swiperOption.autoplay = { delay: 5000 };
    }
  },
};
